<script setup lang="ts">
import type { WritableComputedRef } from 'vue'
import { useModal } from 'vue-final-modal'
import type {
  IManagerTableEmits,
  IManagerTableProps,
} from '@manager/components/Group/Table/types'
import { type INodeObject, parseSizeToGrid, type RepeaterItem } from '@manager'
import {
  Table as OptionExerciseDetailsTable,
  Form as OptionExerciseDetailsForm,
} from '@register/components/Review/Form/OptionExerciseDetails'
import type { IOptionExerciseDetailsValue, IOptionsForm } from './types'
import { useOptionExerciseDetailsData } from '@manager/components/Group/Custom/OptionExerciseDetailsTable/composables/useOptionExerciseDetailsData'

const props = defineProps<IManagerTableProps>()
const emit = defineEmits<IManagerTableEmits>()

const colSize = computed(() => parseSizeToGrid(props.node.size))

const objectNode = props.node.nodes[0] as INodeObject
const [parentValue, dataModelValue] = useObjectVModel(
  {
    ...props,
    node: objectNode,
  },
  'parentValue',
  emit,
)

const { dateFormat: leaseDateFormat } = useCurrentLeaseDocument()

const { formValue } = useManagerFormValue<IOptionsForm>()
const leaseEndDate = computed(
  () => formValue.value.SupportFieldsGroup?.data?.[0]?.ExpiryDate,
)

const { filteredItems, update, getById } = useOptionExerciseDetailsData(
  dataModelValue as WritableComputedRef<IOptionExerciseDetailsValue[]>,
  formValue,
)

const itemValue = ref<RepeaterItem<IOptionExerciseDetailsValue>>()
const {
  open: openModal,
  close,
  patchOptions,
} = useModal({
  component: OptionExerciseDetailsForm,
  attrs: {
    isManager: true,
    onConfirm: (item) => {
      try {
        // Updating
        if (itemValue.value) {
          update(itemValue.value.__id, {
            ExerciseStartDate: item.exerciseStartDate,
            ExerciseEndDate: item.exerciseEndDate,
          })
          close().then(() => {
            itemValue.value = undefined
            patchOptions({
              attrs: {
                // @ts-expect-error
                optionExerciseDetail: undefined,
              },
            })
          })
        }
      } catch (e) {
        console.error(e)
      }
    },
    onClose: () => {
      close().then(() => {
        itemValue.value = undefined
        patchOptions({
          attrs: {
            // @ts-expect-error
            optionExerciseDetail: undefined,
          },
        })
      })
    },
  },
})

const onEdit = (id: string) => {
  const item = getById(id)
  if (item) {
    itemValue.value = item

    patchOptions({
      attrs: {
        optionExerciseDetail: {
          exerciseStartDate: item.ExerciseStartDate ?? undefined,
          exerciseEndDate: item.ExerciseEndDate ?? undefined,
        },
      },
    })
    openModal()
  }
}

const onExercise = (id: string, value?: boolean) => {
  update(id, {
    IsExercised: !value,
  })
}
</script>

<template>
  <div :class="[colSize, 'grid grid-flow-row grid-cols-1 gap-2']">
    <OptionExerciseDetailsTable
      :label="node.label ?? undefined"
      :data="filteredItems"
      :lease-date-format="leaseDateFormat"
      :empty-message="node.emptyMessage"
      :original-expiry-date="leaseEndDate"
      :current-expiry-date="leaseEndDate"
      can-exercise
      @edit="({ __id }) => onEdit(__id)"
      @exercise="({ __id, isExercised }) => onExercise(__id, isExercised)"
    />
  </div>
</template>
