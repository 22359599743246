import type { MaybeRef, WritableComputedRef } from 'vue'
import type {
  IOptionExerciseDate,
  IOptionExerciseDetailsValue,
  IOptionsConfiguration,
  IOptionsForm,
} from '../types'
import type { IOptionExerciseDetailsValue as IOptionExerciseDetailsTableValue } from '@register/components/Review/Form/OptionExerciseDetails'

export function useOptionExerciseDetailsData(
  initialValue: MaybeRef<IOptionExerciseDetailsValue[]>,
  formValue: WritableComputedRef<IOptionsForm>,
) {
  const { items, filteredItems, add, update, remove, getById } = useRepeater(
    initialValue,
    {
      scrollAndBlink: false,
    },
  )

  const dayjs = useDayjs()
  const expiryDate = computed(
    () => formValue.value.SupportFieldsGroup?.data?.[0]?.ExpiryDate,
  )

  const MAX_ITERATIONS = 200
  let totalNumber = 0

  debouncedWatch(
    [
      () => formValue.value.OptionsConfiguration?.data ?? [],
      () => formValue.value.ExerciseStartDate,
      () => formValue.value.ExerciseEndDate,
    ],
    ([newConfigurations, ExerciseStartDate, ExerciseEndDate]) => {
      if (!expiryDate.value) return

      let currentNumber = 0

      for (let index = 0; index < newConfigurations.length; index++) {
        if (currentNumber >= MAX_ITERATIONS) break // Break if total iterations reach the maximum limit
        const newConfig = newConfigurations[index]

        for (let i = 0; i < Number(newConfig.Number || 0); i++) {
          if (currentNumber >= MAX_ITERATIONS) break // Break if total iterations reach the maximum limit

          const prevItem = items.value[currentNumber - 1]
          const prevConfig = newConfigurations[i === 0 ? index - 1 : index]

          addOrUpdateItem(currentNumber, {
            ExerciseStartDate: ExerciseStartDate
              ? generateExerciseDate(
                  prevItem?.ExerciseStartDate || expiryDate.value,
                  ExerciseStartDate,
                  prevConfig,
                )
              : undefined,
            ExerciseEndDate: ExerciseEndDate
              ? generateExerciseDate(
                  prevItem?.ExerciseEndDate || expiryDate.value,
                  ExerciseEndDate,
                  prevConfig,
                )
              : undefined,
          })

          currentNumber++
        }
      }

      removeExtraItems(currentNumber)
      totalNumber = currentNumber
    },
    { immediate: true, deep: true, debounce: 300 },
  )

  return {
    items,
    filteredItems: computed(() =>
      filteredItems.value.map<IOptionExerciseDetailsTableValue>((item) => ({
        __id: item.__id,
        option: item.Option ?? undefined,
        exerciseStartDate: item.ExerciseStartDate ?? undefined,
        exerciseEndDate: item.ExerciseEndDate ?? undefined,
        isExercised: item.IsExercised ?? undefined,
      })),
    ),
    add,
    update,
    remove,
    getById,
  }

  function generateExerciseDate(
    date: string,
    exerciseDate: IOptionExerciseDate,
    config: IOptionsConfiguration | undefined,
  ) {
    if (!config) {
      return dayjs(date)
        .subtract(Number(exerciseDate?.Months || 0), 'months')
        .subtract(Number(exerciseDate?.Days || 0), 'days')
        .toISOString()
    }

    return dayjs(date)
      .add(Number(config.Years || 0), 'year')
      .add(Number(config.Months || 0), 'month')
      .add(Number(config.Days || 0), 'day')
      .toISOString()
  }

  function addOrUpdateItem(
    currentNumber: number,
    exerciseDates: { ExerciseStartDate?: string; ExerciseEndDate?: string },
  ) {
    const item = items.value[currentNumber]
    if (item) {
      update(item.__id, {
        Option: currentNumber + 1,
        ...exerciseDates,
      })
      item.__deleted = false
    } else {
      add({
        Option: currentNumber + 1,
        ...exerciseDates,
        IsExercised: false,
      } as IOptionExerciseDetailsValue)
    }
  }

  function removeExtraItems(currentNumber: number) {
    if (totalNumber > currentNumber) {
      for (let i = totalNumber - 1; i >= currentNumber; i--) {
        const item = items.value[i]
        if (item && !item.IsExercised) {
          remove(item.__id)
        }
      }
    }
  }
}
